<template>
  <div class="dashboard1 p-5" id="dementie-information">
    <div class="row">
        <div class="col-sm-12 down">
          <h1 class="intro-section-title">Register DementieZorg en - Ondersteuning (DZO)</h1>
          <div class="row">
            <p class="intro-section-text">
              Najaar 2020 is de Nationale Dementiestrategie 2021-2030 gepubliceerd. Onderdeel daarvan is voortzetting van het Register DementieZorg en -Ondersteuning (DZO). Een samenwerking tussen Amsterdam Data Collective en Erasmus Universiteit Rotterdam in opdracht van het Ministerie van Volksgezondheid, Welzijn en Sport.
            </p>
          </div>
          <div class="row custom-space">
            <h2 class="intro-section-subtitle">Hulp</h2>
            <p class="intro-section-text">
              Dit dashboard is in ontwikkeling en wordt periodiek bijgewerkt. Wij proberen u van zo goed mogelijke informatie te voorzien. Heeft u vragen, opmerkingen, toevoegingen of onjuistheden die van toepassing zijn op dit dashboard, stuur dan een bericht naar <a href="mailto:dementiedata@amsterdamdatacollective.com" class="custom-link">dementiedata@amsterdamdatacollective.com</a>.
            </p>
          </div>
          <div class="row custom-space">
            <h2 class="intro-section-subtitle">Datadefinitie</h2>
            <h3 class="intro-section-subtitle">Hoe zijn mensen met dementie geïdentificeerd?</h3>
            <p class="intro-section-text">
              In dit dashboard worden cijfers gepubliceerd over het gebruik van zorg en ondersteuning van mensen met dementie en hun naasten. Veel cijfers zijn gebaseerd op eigen berekeningen op basis van de <a href='www.cbs.nl/microdata' class="custom-link"> <nobr>CBS Microdata</nobr></a>. Er is in Nederland geen uitputtend overzicht van alle mensen met dementie. Daarom identificeren we mensen met dementie in die data geïdentificeerd op basis van zorggebruik waarvoor is geregistreerd dat deze zorg vanwege de diagnose dementie wordt gebruikt. We combineren daartoe informatie uit de volgende bronnen.
            </p>
          </div>
        </div>
      </div>


    <table class="table custom-margin">
      <thead class="table-bg">
        <tr>
          <th scope="col">Bron</th>
          <th scope="col">Jaren</th>
          <th scope="col">Dekking</th>
          <th scope="col">Identificatie</th>
          <th scope="col">Bronhouder</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">Medicatie</th>
          <td>2006-2022</td>
          <td>Landelijk</td>
          <td>Galantamine, memantine, rivastigmine en donepezil</td>
          <td><a href='https://www.vektis.nl/herijking-methode-bepaling-dementiepopulatie' class="custom-link">Vektis</a></td>
        </tr>
        <tr>
          <th scope="row">Ziekenhuisopname</th>
          <td>2015-2019</td>
          <td>Landelijk</td>
          <td>Ontslagdiagnose dementie (Nivel-definitie; ICD-codes F00-F02 en G30)</td>
          <td>DHD</td>
        </tr>
        <tr>
          <th scope="row">Ziekenhuisbezoek</th>
          <td>2013-2020</td>
          <td>Landelijk</td>
          <td>Diagnose dementie syndromen (neurologie); diagnose geheugenproblemen en dementie (inwendige geneeskunde); diagnose geheugenproblemen en dementie (klinische geriatrie).</td>
          <td><a href='https://www.vektis.nl/herijking-methode-bepaling-dementiepopulatie' class="custom-link">Vektis</a></td>
        </tr>
        <tr>
          <th scope="row">Wlz-indicatie</th>
          <td>2015-2021</td>
          <td>Landelijk</td>
          <td>Grondslag: psychogeriatrische aandoening Zorgzwaartepakket Verpleging en Verzorging 5 (beschermd wonen met intensieve dementiezorg)in het huidige of het volgende kalenderjaar.</td>
          <td>CIZ</td>
        </tr>
        <tr>
          <th scope="row">Wlz-gebruik</th>
          <td>2015-2021</td>
          <td>Landelijk</td>
          <td>Zorgzwaartepakket Verpleging en Verzorging 5 (beschermd wonen met intensieve dementiezorg)</td>
          <td>CAK</td>
        </tr>
        <tr>
          <th scope="row">Doodsoorzaak</th>
          <td>2015-2021</td>
          <td>Landelijk</td>
          <td>Doodsoorzaak dementie (CBS-definitie; ICD-codes F00-F02 en G30-G31) in het huidige of het volgende kalenderjaar</td>
          <td>CBS</td>
        </tr>
        <tr>
          <th scope="row">Wijkverpleging</th>
          <td>2017-2020</td>
          <td>Landelijk</td>
          <td>Zorg aan kwetsbare ouderen en chronisch zieken, langer dan 3 maanden (psychogeriatrisch/psychiatrisch), Ketenzorg dementie, PG, PG-complex</td>
          <td><a href='https://www.vektis.nl/herijking-methode-bepaling-dementiepopulatie' class="custom-link">Vektis</a></td>
        </tr>
        <tr>
          <th scope="row">GGZ</th>
          <td>2015-2020</td>
          <td>Landelijk</td>
          <td>Diagnose Delirium, dementie, amnestische en overige cognitieve stoornissen. Zie ook <a href='https://www.vektis.nl/herijking-methode-bepaling-dementiepopulatie' class="custom-link"> https://www.vektis.nl/herijking-methode-bepaling-dementiepopulatie</a>.</td>
          <td><a href='https://www.vektis.nl/herijking-methode-bepaling-dementiepopulatie' class="custom-link">Vektis</a></td>
        </tr>
      </tbody>
    </table>


    <div class="row">
        <div class="col-sm-12 down">
          <div class="intro-section-colour-block">
            <div class="row">
              <p class="intro-section-text">
                Wij volgen de methodiek gepresenteerd door Vektis m.b.t. inclusie door zorggebruik in wijkverpleging, GGZ, ziekenhuisbezoek inwendige geneeskunde en ziekenhuisbezoek klinische geriatrie. (<a href='https://www.vektis.nl/herijking-methode-bepaling-dementiepopulatie' class="custom-link">https://www.vektis.nl/herijking-methode-bepaling-dementiepopulatie</a>). Iemand wordt toegerekend tot de populatie met twee indicatoren in één jaar, of met twee jaar achter elkaar minimaal één indicator. 
              </p>
            </div>
            <div class="row">
              <p class="intro-section-text">
                Voor de analyses op de pagina Thema huisarts(enpost) (link) is in aanvulling op bovenstaande informatie, ook informatie gebruikt over de diagnose dementie die door de huisarts of huisartsenpost geregistreerd is  (ICPC-code P70 of ATC-codes N06DA02, N06DA03, N06DA04, N06DX01). Deze gegevens worden niet voor de andere analyses gebruikt omdat deze informatie slechts voor een deel van de Nederlandse populatie beschikbaar is. 
              </p>
            </div>
            <div class="row">
              <p class="intro-section-text">
                Daarnaast merken we mensen die in een bepaald jaar dementiezorg of ondersteuning ontvangen ook in de jaren erna aan als een persoon met dementie. Op deze manier identificeren we in 2020 ongeveer 260.000 mensen met dementie. Het werkelijk aantal mensen met dementie ligt vermoedelijk hoger. Mensen met dementie die geen zorg ontvangen, die alleen mantelzorg krijgen of die zorg ontvangen waarvan niet wordt vastgelegd wat de reden is dat iemand die zorg krijgt blijven in deze analyses buiten beeld. De kenmerken van deze groep die buiten beeld blijft wijken vermoedelijk af van die van de mensen met dementie die we wel identificeren. We maken op basis van achtergrondkenmerken een inschatting van het aantal mensen per regio dat buiten beeld blijft. Die schatting wordt gerapporteerd op de pagina <a href='https://dementieinkaart.nl/Identificatie' class="custom-link">Identificatie</a>. Tegelijkertijd zijn er mogelijk mensen met dementie die we identificeren die in werkelijkheid geen dementie hadden. Dat kan bijvoorbeeld gebeuren als de diagnose verkeerd is gesteld door een zorgverlener of wanneer de diagnose-code slechts een vermoeden betreft, bijvoorbeeld bij een verwijzing van een huisarts naar een medisch specialist.
              </p>
            </div>

            <div class="row custom-space">
              <h2 class="intro-section-subtitle">Definities en veelgestelde vragen</h2>
              <h3 class="intro-section-subtitle">Wat wordt verstaan onder polyfarmacie?</h3>
              <p class="intro-section-text">
                Polyfarmacie wordt gebruikt als een persoon tegelijkertijd vijf of meer verschillende medicijnen voorgeschreven heeft gekregen.
              </p>
            </div>
            <div class="row">
              <h3 class="intro-section-subtitle">Wat wordt verstaan onder psychofarmaca?</h3>
              <p class="intro-section-text">
                Zes groepen behoren tot psychofarmaca:
              </p>
              <p class="intro-section-text">
                <ul>
                  <li>Middelen bij dementie (code N06D)</li>
                  <li>Anti-epileptica (code N03A)</li>
                  <li>Antipsychotica (code N05A)</li>
                  <li>Anxiolytica (code N05B)</li>
                  <li>Hypnotica (code N05C)</li>
                  <li>Antidepressiva (code N06A)</li>
                </ul>
              </p>
              <p class="intro-section-text">
                De codes komen uit de “Anatomical Therapeutic Chemical Classification System”. Meer informatie over dit systeem is te vinden op <a href='https://www.who.int/tools/atc-ddd-toolkit/atc-classification' class="custom-link">deze pagina</a>.
              </p>
            </div>
            <div class="row custom-space">
              <h3 class="intro-section-subtitle">Hoe is de regio-indeling tot stand gekomen?</h3>
              <p class="intro-section-text">
                Dementienetwerkregio’s bestaan uit een clustering van gemeenten, we hanteren voor alle jaren de indeling van 2021. Zorgkantoorregio’s bestaan ook uit een clustering van gemeenten. Deze indeling voor zorgkantoren is voor elk jaar specifiek. Het aantal zorgkantoorregio’s is kleiner dan het aantal dementienetwerkregio’s.
              </p>
            </div>
            <div class="row custom-space">
              <h3 class="intro-section-subtitle">Welke soorten stoornissen bepalen mede de identificatie van mensen met dementie?</h3>
              <p class="intro-section-text">
                De Internationale Statistische Classificatie van ziekten en gerelateerde gezondheidsproblemen onderscheidt verschillende klassen. Het Nictiz, de Nederlandse kennisorganisatie voor digitale informatie-uitwisseling in de zorg, heeft een Nederlandstalige versie opgenomen van deze classificatie. De klassen F00, F01, F02, F03 en G30 en G31 zijn stoornissen die veroorzaakt worden door de ziekte dementie. Voor een uitgebreide beschrijving van F00 tot en met F03 zie <a href='https://terminologie.nictiz.nl/terminology/claml/ViewClass?classificationId=2.16.840.1.113883.6.3.2&code=F00-F09' class="custom-link">bron</a>. Voor een beschrijving van G30 en G31 zie: <a href='https://terminologie.nictiz.nl/terminology/claml/ViewClass?classificationId=2.16.840.1.113883.6.3.2&code=G30-G32' class="custom-link">bron</a>.
              </p>
            </div>
            <div class="row custom-space">
              <h3 class="intro-section-subtitle">Waarom verspringen teksten en/of grafieken?</h3>
              <p class="intro-section-text">
                Dit kan twee oorzaken hebben: 1) De pagina is in een klein venster geopend. Voor de leesbaarheid van het dashboard raden wij aan deze pagina op uw gehele scherm te openen. 2) De pagina is in- of uitgezoomd. Voor de leesbaarheid van het dashboard raden wij aan deze pagina altijd met een zoom van 100% te openen. U kunt dit aanpassen met Ctrl + Scroll.
              </p>
              <p class="intro-section-text">
                Mochten grafieken niet goed leesbaar zijn, te ver zijn ingezoomd of geen inzichten geven, dan kan het helpen om de pagina even te verversen.
              </p>
              <p class="intro-section-text">
                Ontwikkeld door ADC en ESHPM in opdracht van het ministerie van Volksgezondheid, Welzijn en Sport. Voor vragen of opmerkingen kunt u contact opnemen met <a class="custom-link" href="mailto:dementiedata@amsterdamdatacollective.com">dementiedata@amsterdamdatacollective.com</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>
</template>

<script>
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
    metaInfo: {
      title: 'About',
    },
  name: 'About',
  components: { Footer},
  data () {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
    }
  }
}

</script>

<style scoped>

/*.dashboard1{
  font-size: 200%;
}*/

.intro-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}

.intro-section-subtitle{
  font-weight: bold;
  margin-bottom: 2rem;
}


.custom-space{
  margin-bottom: 2rem;
}

ul {
  list-style-type: circle;
}


.custom-link {
  margin-right: 0px !important;
  outline: none;
  box-shadow: none;
  color: var(--color-1);
  font-weight: bold;
  text-decoration: none;
  padding: 3px 3px 3px;
}

.custom-link:focus,
.custom-link:hover {
  outline: none;
  color: var(--color-2);
  background-color: var(--color-1);
  border-radius: 10px;
}

.table {
  color: var(--color-1);
}


.table-bg {
    color: white;
    background-color: var(--color-1);
    border-color: var(--color-1);
}

.custom-margin{
  margin: 50px 0 20px 0;
}

</style>
